"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ControlTypesXml = `<?xml version="1.0" encoding="utf-8"?>
<DataType Name="Generic - TrueFalse - Default False" Key="6f5d4f43-114e-4d29-8bb6-e45a2fc2da6a" Id="Umbraco.TrueFalse" DatabaseType="Integer">
  <PreValues>
    <PreValue Id="29" Alias="default"><![CDATA[]]></PreValue>
  </PreValues>
</DataType>
<DataType Name="Generic - Textbox" Key="174068c3-ec07-49cb-8cde-8072c780ce5c" Id="Umbraco.Textbox" DatabaseType="Nvarchar">
  <PreValues>
    <PreValue Id="28" Alias="maxChars"><![CDATA[]]></PreValue>
  </PreValues>
</DataType>
<DataType Name="Generic - Repeatable textstrings" Key="a68d1d43-4d76-4da3-9fa4-c3b607e6b39f" Id="Umbraco.MultipleTextstring" DatabaseType="Ntext">
  <PreValues>
    <PreValue Id="22" Alias="0"><![CDATA[{"Minimum":0,"Maximum":0}]]></PreValue>
  </PreValues>
</DataType>
<DataType Name="Generic - Textarea" Key="8db63aa6-4660-4a7f-924c-048f4e8c7e79" Id="Umbraco.TextboxMultiple" DatabaseType="Ntext">
  <PreValues>
    <PreValue Id="27" Alias="maxChars"><![CDATA[]]></PreValue>
  </PreValues>
</DataType>`;
exports.default = ControlTypesXml;
