"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AdviserDetailsXML = `<?xml version="1.0" encoding="utf-8"?>
<DocumentType>
  <Info>
    <Key>bd0d6546-167a-4306-b661-ae3810cff82e</Key>
    <Name>Adviser Details</Name>
    <Alias>adviserDetails</Alias>
    <Icon>icon-umb-content color-black</Icon>
    <Thumbnail>folder.png</Thumbnail>
    <Description></Description>
    <AllowAtRoot>False</AllowAtRoot>
    <IsListView>False</IsListView>
    <Folder>Pages</Folder>
    <Compositions />
    <DefaultTemplate>AdviserDetails</DefaultTemplate>
    <AllowedTemplates>
      <Template>AdviserDetails</Template>
    </AllowedTemplates>
  </Info>
  <Structure />
  <GenericProperties>
    <GenericProperty>
      <Key>857d5aa7-a018-4fb1-8b0f-e7003d5c7370</Key>
      <Name>Address</Name>
      <Alias>address</Alias>
      <Definition>8db63aa6-4660-4a7f-924c-048f4e8c7e79</Definition>
      <Type>Umbraco.TextboxMultiple</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact address. This will not appear if empty.]]></Description>
      <SortOrder>6</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>bcc39bbb-cadf-48a1-9ad9-1534227d117c</Key>
      <Name>Description</Name>
      <Alias>contactDescription</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact description. This appears with Name]]></Description>
      <SortOrder>1</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>303bb3a4-a1db-41d6-bd1b-38b8a04698bc</Key>
      <Name>Contact Details Type</Name>
      <Alias>contactDetailsType</Alias>
      <Definition>2ff40d71-6821-4232-a680-70a991d5271d</Definition>
      <Type>Umbraco.RadioButtonList</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>2</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>5b3502ee-859f-45e5-9aa3-f9e6f1cf3d47</Key>
      <Name>Name</Name>
      <Alias>contactName</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact name. This will not appear if empty.]]></Description>
      <SortOrder>0</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>579eae0a-bf9c-4e97-abd9-05085ae76645</Key>
      <Name>Email Address</Name>
      <Alias>emailAddress</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact email address. This will not appear if empty.]]></Description>
      <SortOrder>4</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>13c3ebb1-9497-4e0e-8f8c-8dbd5ceb35fc</Key>
      <Name>Hide Photos</Name>
      <Alias>hideAvatars</Alias>
      <Definition>6f5d4f43-114e-4d29-8bb6-e45a2fc2da6a</Definition>
      <Type>Umbraco.TrueFalse</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>3</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>9bb209ba-75a3-4c17-afbf-7c6cc7a0ce9b</Key>
      <Name>Introduction Text</Name>
      <Alias>introductionText</Alias>
      <Definition>8db63aa6-4660-4a7f-924c-048f4e8c7e79</Definition>
      <Type>Umbraco.TextboxMultiple</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>1</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>776682c3-759d-4e33-8b9b-828f16ca1f41</Key>
      <Name>No Adviser Text</Name>
      <Alias>noAdviserText</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>1</SortOrder>
      <Tab>Adviser Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>9a6814f3-2bf9-438a-b1c0-31e31b7c7758</Key>
      <Name>Opening Hours</Name>
      <Alias>openingHours</Alias>
      <Definition>8db63aa6-4660-4a7f-924c-048f4e8c7e79</Definition>
      <Type>Umbraco.TextboxMultiple</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact opening hours. This will not appear if empty.]]></Description>
      <SortOrder>5</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>512c06b2-3e82-4495-bce9-99985eb06bc4</Key>
      <Name>Page Heading</Name>
      <Alias>pageHeading</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>0</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>ad531850-3c01-4305-8a7e-b1fa1217b4d6</Key>
      <Name>Show Map Text</Name>
      <Alias>showMapText</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[]]></Description>
      <SortOrder>0</SortOrder>
      <Tab>Adviser Details</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>caea0010-42db-438e-921b-5397afed745c</Key>
      <Name>Telephone Number</Name>
      <Alias>telephoneNumber</Alias>
      <Definition>174068c3-ec07-49cb-8cde-8072c780ce5c</Definition>
      <Type>Umbraco.Textbox</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Contact telephone number. This will not appear if empty.]]></Description>
      <SortOrder>2</SortOrder>
      <Tab>General Contact Details</Tab>
    </GenericProperty>
  </GenericProperties>
  <Tabs>
    <Tab>
      <Caption>Settings</Caption>
      <SortOrder>0</SortOrder>
    </Tab>
    <Tab>
      <Caption>Adviser Details</Caption>
      <SortOrder>1</SortOrder>
    </Tab>
    <Tab>
      <Caption>General Contact Details</Caption>
      <SortOrder>2</SortOrder>
    </Tab>
  </Tabs>
</DocumentType>`;
exports.default = AdviserDetailsXML;
