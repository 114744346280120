"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const components_react_1 = require("@iress/components-react");
const TextArea = (properties) => {
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ css: (0, react_1.css) `
        width: 50%;
        max-width: 400px;
      ` }, { children: (0, jsx_runtime_1.jsx)(components_react_1.IressInput, { placeholder: properties.Description, rows: 5, value: properties.data.content, onChange: properties.onChange }) })));
};
exports.default = TextArea;
