"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AnalyisXML = `<?xml version="1.0" encoding="utf-8"?>
<DocumentType>
  <Info>
    <Key>6690a944-d204-40bb-bf94-263bc60d7fca</Key>
    <Name>Analysis</Name>
    <Alias>analysis</Alias>
    <Icon>icon-umb-content color-black</Icon>
    <Thumbnail>folder.png</Thumbnail>
    <Description></Description>
    <AllowAtRoot>False</AllowAtRoot>
    <IsListView>False</IsListView>
    <Folder>Pages</Folder>
    <Compositions />
    <DefaultTemplate>Analysis</DefaultTemplate>
    <AllowedTemplates>
      <Template>Analysis</Template>
    </AllowedTemplates>
  </Info>
  <Structure>
    <DocumentType Key="680ffb78-7763-449f-bee7-0f430f71a1de">allocationSection</DocumentType>
    <DocumentType Key="b4856d69-7662-46f5-9910-6667b76a9609">investmentsSection</DocumentType>
    <DocumentType Key="50f20ae5-dd4a-4616-b62f-c21f33c2d519">performanceSection</DocumentType>
    <DocumentType Key="62200e9e-6962-4111-a0af-f92bae6eb8d3">transactionsSection</DocumentType>
  </Structure>
  <GenericProperties>
    <GenericProperty>
      <Key>850657f4-1902-497f-8064-66dcde97355d</Key>
      <Name>Hide date filter options</Name>
      <Alias>dateFilterOptions</Alias>
      <Definition>a660ebc5-5113-4bbb-b463-11fc65fd692f</Definition>
      <Type>Umbraco.CheckBoxList</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Select which date ranges should be hidden from clients on the Analysis screen's date picker]]></Description>
      <SortOrder>0</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
      <Key>603d8974-561a-42fc-9e04-9f34111ec5e3</Key>
      <Name>Make Investments tab default</Name>
      <Alias>investmentTabIsDefault</Alias>
      <Definition>6f5d4f43-114e-4d29-8bb6-e45a2fc2da6a</Definition>
      <Type>Umbraco.TrueFalse</Type>
      <Mandatory>false</Mandatory>
      <Validation></Validation>
      <Description><![CDATA[Select whether you would like to have the Investment tab as the default selection when clients land on the Analysis screen]]></Description>
      <SortOrder>1</SortOrder>
      <Tab>Settings</Tab>
    </GenericProperty>
    <GenericProperty>
    <Key>e00cf698-7ae2-4bb3-820d-7981790649ab</Key>
    <Name>Make Investment rows expanded by default</Name>
    <Alias>investmentRowsExpandedByDefault</Alias>
    <Definition>6f5d4f43-114e-4d29-8bb6-e45a2fc2da6a</Definition>
    <Type>Umbraco.TrueFalse</Type>
    <Mandatory>false</Mandatory>
    <Validation></Validation>
    <Description><![CDATA[When clients select the Investments Tab on the Analysis screen, set to true to have all the investment rows expanded by default]]></Description>
    <SortOrder>2</SortOrder>
    <Tab>Settings</Tab>
  </GenericProperty>
  </GenericProperties>
  <Tabs>
    <Tab>
      <Caption>Settings</Caption>
      <SortOrder>0</SortOrder>
    </Tab>
  </Tabs>
</DocumentType>`;
exports.default = AnalyisXML;
