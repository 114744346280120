"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
/** @jsxImportSource @emotion/react */
const react_1 = require("@emotion/react");
const components_react_1 = require("@iress/components-react");
const TrueFalse = (properties) => {
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ css: (0, react_1.css) `
        width: 50%;
        max-width: 400px;
      ` }, { children: (0, jsx_runtime_1.jsx)(components_react_1.IressCheckbox, Object.assign({ value: "true", checked: properties.data.checked, onChange: properties.onChange }, { children: properties.Description })) })));
};
exports.default = TrueFalse;
